
export const marketplaceProductsMixin = {

  methods: {
    async saveMarketplaceProduct(edit = false) {
      if(!this.MP.firstValidationAdmin()) return

      let data = this.MP.prepareSave()
      let dispatchType = 'createMarketplaceProducts'

      console.log(data);

      if(edit) {
        dispatchType = 'updateMarketplaceProducts'
        data = {
          id: this.MP.getId(),
          data: data,
        }
      }

      let valid = true
      if(this.MP.data.Files.getFiles().length > 0) {
        valid = await this.validateFiles()
      }
      if(!valid) return

      this.$store.dispatch(dispatchType, data).then(async response => {
        switch (this.getResponseStatus(response)) {
          /**
           * Success
           */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
            if(this.MP.data.Files.getFiles().length > 0) {
              await this.createFiles(response.data.data.id, edit)
            }

            if(this.MP.data.Videos.getFiles().length > 0) {
              await this.createVideos(response.data.data.id, edit)
            }

            if(this.MP.data.isDigitalProduct && (this.MP.data.DigitalProducts.length > 0 || this.MP.data.DigitalSampleProducts.length > 0)) {
              await this.digitalProducts(response.data.data.id, edit)
            }

            if(!edit) {
              this.openNotify('success', 'common_notificationRecordCreated')
            } else {
              this.openNotify('success', 'common_notificationRecordChanged')
            }
            this.$router.push(this.$store.getters.GET_PATHS.marketplaceProducts)
            break
          }
          /**
           * Validation Error
           */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
          /**
           * Undefined Error
           */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }
      })

    },

    digitalProducts(id) {

      let reader = new FormData();

      this.MP.data.DigitalProducts.forEach((item, index) => {
        reader.append(`files[${index}][model_id]`, item.getFileModelId() || '')
        reader.append(`files[${index}][title]`, item.getName())
        reader.append(`files[${index}][price]`, item.getPrice())
        reader.append(`files[${index}][downloads]`, item.getDownload())
        reader.append(`files[${index}][sort_order]`, item.getSortOrder())
        reader.append(`files[${index}][url]`, item.getFileUrl())
        reader.append(`files[${index}][type]`, item.getIsFile() ? 'file' : 'url')
        reader.append(`files[${index}][sample_model_id]`, item.getSampleModelId() || '')
        reader.append(`files[${index}][sample_type]`, item.getIsSampleFile() ? 'file' : 'url')
        reader.append(`files[${index}][sample_url]`, item.getSampleFileUrl())

        if(item.getIsFile()) {
          if(item.data.File.getFiles().length > 0) {
            item.data.File.data.files.map((file) => {
              if(!this._.has(file, 'maxCount') && !file.maxSizeError){
                reader.append(`files[${index}][file]`, file.file)
              }
            })
          } else {
            item.data.File.data.downloadFiles.map((file) => {
              reader.append(`files[${index}][file]`, file.entityId)
            })
          }
        }

        if(item.getIsSampleFile()) {
          if(item.data.SampleFile.getFiles().length > 0) {
            item.data.SampleFile.data.files.map((file) => {
              if(!this._.has(file, 'maxCount') && !file.maxSizeError){
                reader.append(`files[${index}][sample]`, file.file)
              }
            })
          } else {
            item.data.SampleFile.data.downloadFiles.map((file) => {
              reader.append(`files[${index}][sample]`, file.entityId)
            })
          }
        }
      })

      this.MP.data.DigitalSampleProducts.forEach((item, index) => {
        reader.append(`sample[${index}][model_id]`, item.getFileModelId() || '')
        reader.append(`sample[${index}][title]`, item.getName())
        reader.append(`sample[${index}][sort_order]`, item.getSortOrder())
        reader.append(`sample[${index}][type]`, item.getIsFile() ? 'file' : 'url')
        reader.append(`sample[${index}][url]`, item.getFileUrl())

        if(item.getIsFile()) {
          if(item.data.File.getFiles().length > 0) {
            item.data.File.data.files.map((file) => {
              if(!this._.has(file, 'maxCount') && !file.maxSizeError){
                reader.append(`sample[${index}][file]`, file.file)
              }
            })
          } else {
            item.data.File.data.downloadFiles.map((file) => {
              reader.append(`sample[${index}][file]`, file.entityId)
            })
          }
        }
      })

      Array.from(reader.entries())
        .map((entry, index) => {
          console.log(`${index}: ${entry}`)
        })

      let data = {
        id: this.MP.getId() === -1 ? id : this.MP.getId(),
        data: reader
      }

      return this.$store.dispatch('addDigitalProducts', data).then((response) => {
        this.responseProcessing(response)
      }).catch(error => this.createErrorLog(error))
    },

    validateFiles() {
      let reader = new FormData();
      this.MP.data.Files.data.files.map((item,index) => {
        if(!this._.has(item, 'maxCount') && !item.maxSizeError){
          reader.append(`photo[${index}]`, item.item);
        }
      })
      for (let pair of reader.entries()) {
        console.log(pair[0]+ ', ' + pair[1]);
      }

      return this.$store.dispatch('validatePhotoToMarketplaceProduct', reader).then((response) => {
        return this.responseProcessing(response)
      }).catch(error => this.createErrorLog(error))
    },

    createFiles(id) {
      // let data = {}
      let reader = new FormData();
      // let files = []
      this.MP.data.Files.data.files.map((item,index) => {
        if(!this._.has(item, 'maxCount') && !item.maxSizeError){
          // files.push(item)
          console.log(item);
          reader.append(`file[${index}]`, item.item);
        }
      })

      let data = {
        id: this.MP.getId() === -1 ? id : this.MP.getId(),
        data: reader
      }

      return this.$store.dispatch('addPhotoToMarketplaceProduct', data).then((response) => {
        this.responseProcessing(response)
      }).catch(error => this.createErrorLog(error))
    },

    createVideos(id) {
      let reader = new FormData();
      this.MP.data.Videos.data.files.map((item,index) => {
        console.log(222);
        console.log(item);
        if(!this._.has(item, 'maxCount') && !item.maxSizeError){
          reader.append(`file[${index}]`, item.file);
        }
      })

      let data = {
        id: this.MP.getId() === -1 ? id : this.MP.getId(),
        data: reader
      }

      return this.$store.dispatch('addVideoToMarketplaceProduct', data).then((response) => {
        this.responseProcessing(response)
      }).catch(error => this.createErrorLog(error))
    },

    responseProcessing(response) {
      switch (this.getResponseStatus(response)) {
        /**
         * Success
         */
        case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

          // if(!edit) {
          //   this.openNotify('success', 'common_notificationRecordCreated')
          // } else {
          //   this.openNotify('success', 'common_notificationRecordChanged')
          // }
          // this.$router.push(this.$store.getters.GET_PATHS.marketplaceProducts)
          return true
        }
        /**
         * Validation Error
         */
        case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
          let errors = response.response.data.errors;
          this.notifyErrorHelper(errors)
          return false
        }
        /**
         * Undefined Error
         */
        default: {
          this.openNotify('error', 'common_notificationUndefinedError')
          return false
        }
      }
    },

    removeItem(id) {
      let text = {
        title: 'etsyListings_DeleteEtsyListings',
        txt: '',
        yes: 'common_confirmDelete',
        no: 'common_confirmNo'
      }

      let confirm = () => {

        this.$store.dispatch('deleteMarketplaceProducts', id).then((response) => {
          if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
            this.$emit('reload')
            this.openNotify('success', 'common_notificationRecordDeleted')
          } else {
            setTimeout(() => {
              this.openNotify('error', 'common_notificationUndefinedError')
            }, 200)
          }
        })

        this.$store.commit('setConfirmDeletePopup', false)
        return true
      }

      this.deleteFunc(text, confirm)
    },

  }

}
