<template>
  <div class="cus-section">
    <div class="custom-row">
      <div class="custom-col custom-col--sxl-66 custom-col--md-100">
        <div class="section-label large"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['etsyListing_InStock'])"></div>
          {{ $t('etsyListing_InStock.localization_value.value') }}
        </div>

        <div class="custom-row">
          <div class="custom-col custom-col--16 custom-col--sxl-33 custom-col--sm-50 custom-col--es-100 mb-0"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['etsyListing_InStock'])"></div>
            <LargeRadio
                class="soft-text mw-100"
                :label="$t('etsyListing_InStock.localization_value.value')"
                :name="'params-stock'"
                @change="(val) => {MP.data.inStock = 0}"
                :checked="MP.data.inStock === 0"
                :value="MP.data.inStock === 0"
            />
          </div>
          <div class="custom-col custom-col--16 custom-col--sxl-33 custom-col--sm-50 custom-col--es-100 mb-0"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}"
          >
            <div class="admin-edit" @click="editTranslate(['etsyListing_inactiveStatus'])"></div>
            <LargeRadio
                class="soft-text mw-100"
                :label="$t('etsyListing_MadeToOrder.localization_value.value')"
                :name="'params-stock'"
                @change="(val) => {MP.data.inStock = 1}"
                :checked="MP.data.inStock === 1"
                :value="MP.data.inStock === 1"
            />
          </div>
        </div>
      </div>

      <template v-if="MP.data.inStock === 1">
        <div class="custom-col custom-col--25 custom-col--md-50 custom-col--es-100">
          <DefaultSelect
              :label="$t('etsyListing_ProcessingTime.localization_value.value')"
              :options="optionProcessing"
              v-bind:class="{'ui-no-valid': MP.validation.selectedType}"
              :error="MP.validation.selectProcessingDays"
              :errorTxt="$t(`${MP.validation.selectProcessingDays}.localization_value.value`)"
              :optionsLabel="'name'"
              :selected="MP.data.selectProcessingDays"
              :disabled="MP.data.customProcessingRange"
              :clearable="false"
              @change="val => MP.data.selectProcessingDays = val"
          />

          <DefaultCheckbox
              :label="$t('etsyListing_CustomRange.localization_value.value')"
              class="mb-2 mt-3"
              v-model="MP.data.customProcessingRange"
          />
        </div>


        <template v-if="MP.data.customProcessingRange">

          <div class="custom-col d-flex align-items-center mb-3">
            <RadioDefault
                class="wfc mr-3"
                :label="$t('etsyListing_CustomDays.localization_value.value')"
                :name="'customRange'"
                :value="MP.data.customProcessingType === 0"
                @change.native="MP.data.customProcessingType = 0"
            />
            <RadioDefault
                class="wfc"
                :label="$t('etsyListing_CustomWeeks.localization_value.value')"
                :name="'customRange'"
                :value="MP.data.customProcessingType === 1"
                @change.native="MP.data.customProcessingType = 1"
            />
          </div>

          <div class="custom-col d-flex align-items-center">

            <DefaultSelect
                style="width: 200px;"
                class="mr-2"
                :label="''"
                :options="optionsCustom"
                v-bind:class="{'ui-no-valid': MP.validation.selectProcessingFrom}"
                :error="MP.validation.selectProcessingFrom"
                :errorTxt="$t(`${MP.validation.selectProcessingFrom}.localization_value.value`)"
                :optionsLabel="'value'"
                :selected="MP.data.selectProcessingFrom"
                :clearable="false"
                @change="val => MP.data.selectProcessingFrom = val"
            />
            —
            <DefaultSelect
                class="ml-2"
                style="width: 200px;"
                :label="''"
                :options="optionsCustom"
                v-bind:class="{'ui-no-valid': MP.validation.selectProcessingTo}"
                :error="MP.validation.selectProcessingTo"
                :errorTxt="$t(`${MP.validation.selectProcessingTo}.localization_value.value`)"
                :optionsLabel="'value'"
                :selected="MP.data.selectProcessingTo"
                :clearable="false"
                @change="val => MP.data.selectProcessingTo = val"
            />
          </div>
        </template>
      </template>




    </div>
  </div>
</template>

<script>
import LargeRadio from "@/components/UI/radiobuttons/LargeRadio/LargeRadio";
import DefaultSelect from "@/components/UI/selectiones/DefaultSelect/DefaultSelect";
import DefaultCheckbox from "@/components/UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
import RadioDefault from "@/components/UI/radiobuttons/RadioDefault/RadioDefault";
export default {
  name: "InStock",
  components: {RadioDefault, DefaultCheckbox, DefaultSelect, LargeRadio},

  props: {
    MP: Object
  },

  data() {
    return {
      optionProcessing: [
        {value: {dFrom: 0, dTo: 1}, name: '1 day'},
        {value: {dFrom: 1, dTo: 2}, name: '1-2 days'},
        {value: {dFrom: 1, dTo: 3}, name: '1-3 days'},
        {value: {dFrom: 3, dTo: 5}, name: '3-5 days'},
        {value: {dFrom: 5, dTo: 7}, name: '5-7 days'},
      ],
      optionsCustom: [
        {value: 1},
        {value: 2},
        {value: 3},
        {value: 4},
        {value: 5},
        {value: 6},
        {value: 7},
        {value: 8},
        {value: 9},
        {value: 10},
      ],
    }
  },

  mounted() {
    this.optionProcessing = this.MP.data.selectProcessingDaysTypes
  },

}
</script>

<style scoped>

</style>