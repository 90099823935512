<template>
  <!--v-if="$store.getters.getIsAdminRights === 'user'"-->
  <div>
    <MarketplaceProductsEditUser
            class="product-edit-left"
            :MP="MP"
            :historyOfChanged="historyOfChanged"
            :categories="categories"
            @save="saveMarketplaceProduct(true)"
    />
  </div>

</template>

<script>
  import MarketplaceProductsEditUser from "./MarketplaceProductsEditUser/MarketplaceProductsEditUser";
  import {MarketplaceProducts} from "@/components/modules/MarketplaceProductsModule/models/MarketplaceProducts";
  import {marketplaceProductsMixin} from "@/mixins/marketplaceProductsMixins/marketplaceProductsMixin";

  export default {
    name: "MarketplaceProductsEdit",

    components: {
      MarketplaceProductsEditUser,
    },

    mixins: [marketplaceProductsMixin],

    data() {
      return {
        MP: new MarketplaceProducts(),
        categories: [],
        historyOfChanged: null,
      }
    },

    created() {

    },

    mounted() {
      this.$store.dispatch('getMarketplaceProductCategories').then(response => {
        this.categories = response.data
      })

      this.MP.setId(this.$route.params.id)
      this.MP.setShopId(Number(this.$route.params.shopId))

      this.$store.dispatch('getMarketplaceProducts', this.MP.getId()).then(response => {
        let item = this.getRespData(response)

        this.MP.setItem(item, this.isAdmin, true)


        this.getFileFromServer(item?.file_entities).then(files => {
          this.MP.data.Files.setDownloadFiles(files)
        })

      })

      // this.$store.dispatch('fetchShops', '?filter[shopStatus]=valid&filter[type]=12').then(response => {
      //   this.MP.setShopId(this._.first(this.getRespPaginateData(response))?.id)
      // })
    },

    methods: {
      async getFileFromServer(files){
        if(!files) return
        let downloadFiles = []

        for await (let file of files) {
          await this.$store.dispatch('getFileFromServer', file.id)
              .then((response) => {
                let fileBase64 = response
                Object.keys(response).map(item => {
                  downloadFiles.push({
                    id: item,
                    type: file.mime_type,
                    uuid: file.uuid,
                    original_filename: file.original_filename,
                    base64: fileBase64[item],
                  })
                })
              })
        }

        return Promise.resolve(downloadFiles)

      },

    },

  }
</script>

<style scoped>
  @media (max-width: 992px) {
    .product-edit-left >>> .card-detail-left__bg--home {
      background-image: none;
    }
  }
</style>
