import {checkValid} from "@/components/globalModels/functions/ModelsValidate";
import {Dimensions} from "@/components/globalModels/Dimensions";
import {Files} from "@/components/globalModels/Files";
import {MarketplaceVariations} from "@/components/modules/MarketplaceProductsModule/models/MarketplaceVariations";
import _ from 'lodash'
import {DigitalProduct} from "@/components/modules/MarketplaceProductsModule/models/DigitalProduct";
import {DigitalSampleProduct} from "@/components/modules/MarketplaceProductsModule/models/DigitalSampleProduct";


export function MarketplaceProducts() {

  this.checkValid = checkValid

  this.data = {
    id: -1,
    shopId: -1,
    state: 1,
    title: '',
    description: '',
    descriptionShort: '',
    sku: '',
    tags: [],
    materials: [],
    processingMin: null,
    processingMax: null,
    who_made: null,
    when_made: null,
    isSupply: null,
    isPrivate: false,
    nonTaxable: null,
    usedManufacturer: null,
    isVintage: null,

    itemWeightUnit: null,//'oz',
    itemDimensionsUnit: null,//'in',

    Dimensions: new Dimensions(),

    language: 'en-US',

    Files: new Files({
      maxCountFiles: 10,
      maxSizeFiles: 3
    }),

    Videos: new Files({
      maxCountFiles: 3,
      maxSizeFiles: 50
    }),

    isDigitalProduct: false,
    DigitalProducts: [],
    DigitalSampleProducts: [],

    taxonomyPath: [],
    price: '0',
    currencyCode: 'USD',
    quantity: 0,


    arrayPointer: {
      tags: 'tags',
      materials: 'materials'
    },

    MarketplaceVariations: [],
    variationPayload: null,

    inStock: 0,
    selectProcessingDays: {value: {dFrom: 0, dTo: 1}, name: '1 day'},
    selectProcessingDaysTypes: [
      {value: {dFrom: 0, dTo: 1}, name: '1 day'},
      {value: {dFrom: 1, dTo: 2}, name: '1-2 days'},
      {value: {dFrom: 1, dTo: 3}, name: '1-3 days'},
      {value: {dFrom: 3, dTo: 5}, name: '3-5 days'},
      {value: {dFrom: 5, dTo: 7}, name: '5-7 days'},
    ],

    customProcessingRange: false,
    customProcessingType: 0,
    selectProcessingFrom: {value: 1},
    selectProcessingTo: {value: 1},

  }

  this.validation = {
    title: false,
    description: false,
    sku: false,
    processingMin: false,
    processingMax: false,
    who_made: false,
    when_made: false,
    price: false,
    quantity: false,
  }

  this.validationTranslate = {
    title: '',
    description: '',
    sku: '',
    processingMin: '',
    processingMax: '',
    who_made: '',
    when_made: '',
    price: '',
    quantity: '',
  }

  this.validationTxt = {
    title: false,
    description: false,
    sku: false,
    processingMin: false,
    processingMax: false,
    who_made: false,
    when_made: false,
    price: false,
    quantity: false,
  }

  /**
   * Getters
   */

  this.getId = () => {
    return this.data.id
  }
  this.getState = () => {
    return this.data.state
  }
  this.getShopId = () => {
    return this.data.shopId
  }
  this.getTitle = () => {
    return this.data.title
  }
  this.getDescription = () => {
    return this.data.description
  }
  this.getDescriptionShort = () => {
    return this.data.descriptionShort
  }
  this.getSku = () => {
    return this.data.sku
  }
  this.getTags = () => {
    return this.data.tags
  }
  this.getMaterials = () => {
    return this.data.materials
  }
  this.getProcessingMin = () => {
    return this.data.processingMin
  }
  this.getProcessingMax = () => {
    return this.data.processingMax
  }
  this.getWho_made = () => {
    return this.data.who_made
  }
  this.getWhen_made = () => {
    return this.data.when_made
  }
  this.getIsSupply = () => {
    return this.data.isSupply
  }
  this.getIsPrivate = () => {
    return this.data.isPrivate
  }
  this.getNonTaxable = () => {
    return this.data.nonTaxable
  }
  this.getUsedManufacturer = () => {
    return this.data.usedManufacturer
  }
  this.getIsVintage = () => {
    return this.data.isVintage
  }
  this.getItemWeightUnit = () => {
    return this.data.itemWeightUnit
  }
  this.getItemDimensionsUnit = () => {
    return this.data.itemDimensionsUnit
  }
  this.getLanguage = () => {
    return this.data.language
  }
  this.getTaxonomyPath = () => {
    return this.data.taxonomyPath
  }
  this.getPrice = () => {
    return this.data.price
  }
  this.getCurrencyCode = () => {
    return this.data.currencyCode
  }
  this.getQuantity = () => {
    return this.data.quantity
  }


  /**
   * Setters
   */

  this.setId = (val) => {
    return this.data.id = val
  }
  this.setState = (val) => {
    return this.data.state = val
  }
  this.setShopId = (val) => {
    return this.data.shopId = val
  }
  this.setTitle = (val) => {
    return this.data.title = val
  }
  this.setDescription = (val) => {
    return this.data.description = val
  }
  this.setDescriptionShort = (val) => {
    return this.data.descriptionShort = val
  }
  this.setSku = (val) => {
    return this.data.sku = val
  }
  this.setTags = (val) => {
    return this.data.tags = val
  }
  this.setMaterials = (val) => {
    return this.data.materials = val
  }
  this.setProcessingMin = (val) => {
    return this.data.processingMin = val
  }
  this.setProcessingMax = (val) => {
    return this.data.processingMax = val
  }
  this.setWho_made = (val) => {
    return this.data.who_made = val
  }
  this.setWhen_made = (val) => {
    return this.data.when_made = val
  }
  this.setIsSupply = (val) => {
    return this.data.isSupply = val
  }
  this.setIsPrivate = (val) => {
    return this.data.isPrivate = val
  }
  this.setNonTaxable = (val) => {
    return this.data.nonTaxable = val
  }
  this.setUsedManufacturer = (val) => {
    return this.data.usedManufacturer = val
  }
  this.setIsVintage = (val) => {
    return this.data.isVintage = val
  }
  this.setItemWeightUnit = (val) => {
    return this.data.itemWeightUnit = val
  }
  this.setItemDimensionsUnit = (val) => {
    return this.data.itemDimensionsUnit = val
  }
  this.setLanguage = (val) => {
    return this.data.language = val
  }
  this.setTaxonomyPath = (val) => {
    return this.data.taxonomyPath = val
  }
  this.setPrice = (val) => {
    return this.data.price = val
  }
  this.setCurrencyCode = (val) => {
    return this.data.currencyCode = val
  }
  this.setQuantity = (val) => {
    return this.data.quantity = val
  }
  
}


/**
 * Global Setters
 */

MarketplaceProducts.prototype.setItem = function (item, isAdmin, edit = false) {
  console.log(item, isAdmin, edit);

  this.setState(item.state === 'active' ? 1 : 0)
  this.setTitle(item.title)
  this.setDescription(item.description)
  this.setDescriptionShort(item.short_description)
  this.setSku(item.sku)
  this.setTags(item.tags)
  this.setMaterials(item.materials)
  // this.setProcessingMin(item.processing_min)
  // this.setProcessingMax(item.processing_max)
  // this.setWho_made(item.who_made)
  // this.setWhen_made(item.when_made)
  // this.setIsSupply(item.is_supply)
  this.setIsPrivate(item.is_private)
  // this.setNonTaxable(item.non_taxable)
  // this.setUsedManufacturer(item.used_manufacturer)
  // this.setIsVintage(item.is_vintage)
  // this.setItemWeightUnit(item.item_weight_unit)
  // this.setItemDimensionsUnit(item.item_dimensions_unit)
  this.setLanguage(item.language)
  this.setTaxonomyPath(item.taxonomy_id)
  this.setPrice(item.price)
  this.setCurrencyCode(item.currency_code)
  this.setQuantity(item.quantity)

  this.data.Dimensions.setDimensionsItem({
    weight: item.item_weight,
    height: item.item_height,
    width: item.item_width,
    dimensionLength: item.item_length,
  })

  let variations = item?.variation_payload
  if(variations && variations?.products.length > 0) {
    this.data.variationPayload = item.variation_payload

    variations.products.map(variate => {
      let prepareVariation = new MarketplaceVariations()
      prepareVariation.setItem(variate)
      this.data.MarketplaceVariations.push(prepareVariation)
    })

  }

  this.data.isDigitalProduct = item.type === 'downloadable'

  if(item.downloaded_files && item.downloaded_files.length > 0) {

    item.downloaded_files.forEach(productItem => {
      let product = new DigitalProduct()
      product.setName(productItem.title)
      product.setPrice(productItem.price)
      product.setDownload(productItem.downloads)
      product.setSortOrder(productItem.sort_order)
      product.setFileUrl(productItem.url)
      product.setIsFile(productItem.type === 'file')
      product.setFileModelId(productItem.id)

      if(productItem?.file_entries && productItem.file_entries.length > 0) {
        productItem.file_entries.forEach(productFile => {
          product.data.File.setDownloadFiles([{
            id: productFile.id,
            fileName: productFile.original_filename,
            entityId: productItem.id,
          }])
        })
      }

      if(productItem?.sample) {
        product.setSampleModelId(productItem?.sample?.id)
        product.setIsSampleFile(productItem?.sample.type === 'file')
        product.setSampleFileUrl(productItem?.sample.url)

        if(productItem?.sample?.file_entries && productItem.sample.file_entries.length > 0) {

          productItem.sample.file_entries.forEach(productSampleFile => {
            product.data.SampleFile.setDownloadFiles([{
              id: productSampleFile.id,
              fileName: productSampleFile.original_filename,
              entityId: productItem?.sample.id,
            }])
          })
        }
      }

      this.data.DigitalProducts.push(product)
    })
  }

  if(item.downloaded_sample_files && item.downloaded_sample_files.length > 0) {

    item.downloaded_sample_files.forEach(sampleItem => {
      let product = new DigitalSampleProduct()
      product.setName(sampleItem.title)
      product.setSortOrder(sampleItem.sort_order)
      product.setFileUrl(sampleItem.url)
      product.setIsFile(sampleItem.type === 'file')
      product.setFileModelId(sampleItem.id)

      if (sampleItem?.file_entries && sampleItem.file_entries.length > 0) {
        sampleItem.file_entries.forEach(productFile => {
          product.data.File.setDownloadFiles([{
            id: productFile.id,
            fileName: productFile.original_filename,
            entityId: sampleItem.id,
          }])
        })
      }

      this.data.DigitalSampleProducts.push(product)
    })
  }

  if(item.video_files && item.video_files.length > 0) {
    let videos = []
    item.video_files.forEach(video => {
      videos.push({
        id: video.id,
        fileName: video.original_filename,
      })
    })
    this.data.Videos.setDownloadFiles(videos)
  }

  this.data.inStock = item.stock_type

  if(item.stock_type === 1 && item.on_order_range) {
    this.data.customProcessingType = item.on_order_range_type

    let found = _.find(this.data.selectProcessingDaysTypes, itemSelect => {
      if(itemSelect.value.dFrom === item.on_order_range.from &&
        itemSelect.value.dTo === item.on_order_range.to
      ) return itemSelect
    })

    if(found) {
      this.data.selectProcessingDays = found
    } else {
      this.data.customProcessingRange = true
      this.data.selectProcessingFrom.value = item.on_order_range.from
      this.data.selectProcessingTo.value = item.on_order_range.to
    }
  }

}


/**
 * Functions
 */

MarketplaceProducts.prototype.addDigitalProduct = function () {
  this.data.DigitalProducts.push(new DigitalProduct())
  return this.data.DigitalProducts.length
}

MarketplaceProducts.prototype.removeDigitalProduct = function (index) {
  this.data.DigitalProducts.splice(index, 1)
  return this.data.DigitalProducts.length
}

MarketplaceProducts.prototype.addDigitalSample = function () {
  this.data.DigitalSampleProducts.push(new DigitalSampleProduct())
  return this.data.DigitalSampleProducts.length
}

MarketplaceProducts.prototype.removeDigitalSample = function (index) {
  this.data.DigitalSampleProducts.splice(index, 1)
  return this.data.DigitalSampleProducts.length
}

/**
 * Removing TAGS and MATERIAL Items from Arrays
 * @param index
 * @param arrayPointer
 */
MarketplaceProducts.prototype.removeArrayItem = function (index, arrayPointer) {
  let array
  switch (arrayPointer) {
    case this.data.arrayPointer.tags:
      array = this.getTags()
      array.splice(index, 1)
      this.setTags(array)
      break;
    case this.data.arrayPointer.materials:
      array = this.getMaterials()
      array.splice(index, 1)
      this.setMaterials(array)
      break;
  }
}
MarketplaceProducts.prototype.addArrayItem = function (val, arrayPointer) {
  let newArrayParts = val.split(',')
  let array
  let reg
  let maxIndex
  switch (arrayPointer) {
    case 'tags':
      reg = /[^\p{L}\p{Nd}\p{Zs}\-`™©®]/u
      array = this.getTags().concat(newArrayParts)
      maxIndex = 11
      this.setTags(this.getNewArray(array, reg, maxIndex))
      break;
    case 'materials':
      reg = /[^\p{L}\p{Nd}\p{Zs}]/u
      array = this.getMaterials().concat(newArrayParts)
      maxIndex = 12
      this.setMaterials(this.getNewArray(array, reg, maxIndex))
      break;
  }

  /** After entering, I return an empty value to the input **/
  return ''
}
MarketplaceProducts.prototype.getNewArray = function (array, reg, maxIndex) {
  let result = []
  let objCheck = {}
  let newArray = []


  /**
   * Create an object with keys from the resulting array.
   * In this case, the entire name of the keys is trimmed and brought to the lower case.
   **/
  array.map((item) => {
    let ItemAfterTrim = item.trim()
    if (!objCheck[ItemAfterTrim.toLowerCase()]) {
      objCheck[ItemAfterTrim.toLowerCase()] = ItemAfterTrim
    }
  })

  /**
   * Next, we create a new array using the values of the keys
   */
  Object.values(objCheck).map((item) => {
    newArray.push(item)
  })

  /**
   * In the result array we write the values that are "validated"
   */
  newArray.map((item, index) => {
    if (index > maxIndex ||
      item.length === 0 ||
      reg.test(item)) return
    result.push(item.trim())
  })

  return result
}

/**
 * Validations
 */

MarketplaceProducts.prototype.firstValidationAdmin = function () {

  let validationItems = {
    title: this.getTitle(),
    description: this.getDescription(),
    // descriptionShort: this.getDescriptionShort(),
    // sku: this.getSku(),
    // processingMin: this.getProcessingMin(),
    // processingMax: this.getProcessingMax(),
    price: this.getPrice(),
    quantity: this.getQuantity(),
  }

  let validationOptions = {
    title: {type: ['latin', 'empty']},
    description: {type: ['empty']},
    // descriptionShort: {type: ['empty']},
    // sku: {type: ['empty']},
    // processingMin: {type: ['numeric','not-zero','empty']},
    // processingMax: {type: ['numeric','not-zero','empty']},
    price: {type: ['not-zero','empty']},
    quantity: {type: ['numeric','not-zero','empty']},
  }

  let dimensionsValidation = true
  if(!this.data.isDigitalProduct) {
    dimensionsValidation = this.data.Dimensions.dimensionsValidate()
  }

  let variationsValidation = true

  this.data.MarketplaceVariations.map(variate => {
    if(!variate.firstValidation()) return variationsValidation = false
  })

  let digitalValid = true

  if(this.data.isDigitalProduct) {
    this.data.DigitalProducts.map(item => {
      if(!item.firstValidationAdmin()) {
        digitalValid = false
      }
    })

    this.data.DigitalSampleProducts.map(item => {
      if(!item.firstValidationAdmin()) {
        digitalValid = false
      }
    })
  }

  return (this.checkValid(validationItems, validationOptions) && dimensionsValidation && variationsValidation && digitalValid)
}

/**
 * Prepare Data
 */

MarketplaceProducts.prototype.prepareSave = function () {

  let data = {
    'shop_id': this.getShopId(),
    'state': this.getState() === 1 ? 'active' : 'inactive',
    'title': this.getTitle(),
    'description': this.getDescription(),
    'short_description': this.getDescriptionShort(),
    'sku': this.getSku(),
    'tags': this.getTags(),
    'materials': this.getMaterials(),
    'processing_min': this.getProcessingMin(),
    'processing_max': this.getProcessingMax(),
    'who_made': this.getWho_made(),
    'when_made': this.getWhen_made(),
    'is_supply': this.getIsSupply(),
    'is_private': this.getIsPrivate(),
    'non_taxable': this.getNonTaxable(),
    'used_manufacturer': this.getUsedManufacturer(),
    'is_vintage': this.getIsVintage(),
    'item_weight_unit': this.getItemWeightUnit(),
    'item_dimensions_unit': this.getItemDimensionsUnit(),
    'language': this.getLanguage(),
    'taxonomy_id': this.getTaxonomyPath(),
    'price': this.getPrice(),
    'currency_code': this.getCurrencyCode(),
    'quantity': this.getQuantity(),
    'type': this.data.isDigitalProduct ? 'downloadable' : null,

    'item_weight': this.data.Dimensions.getDimensionsWeight(),
    'item_length': this.data.Dimensions.getDimensionsLength(),
    'item_width': this.data.Dimensions.getDimensionsWidth(),
    'item_height': this.data.Dimensions.getDimensionsHeight(),

    'stock_type': this.data.inStock,
  }

  if(this.data.inStock === 1) {
    data['on_order_range_type'] = 0
    if(!this.data.customProcessingRange) {
      data['on_order_range'] = {
        "from": this.data.selectProcessingDays.value.dFrom,
        "to": this.data.selectProcessingDays.value.dTo
      }
    } else {
      data['on_order_range_type'] = this.data.customProcessingType
      data['on_order_range'] = {
        "from": this.data.selectProcessingFrom.value,
        "to": this.data.selectProcessingTo.value
      }
    }
  }

  if(this.data.MarketplaceVariations.length > 0 && this.data.variationPayload) {
    let prepareVariation = this.data.variationPayload
    let prepareProducts = prepareVariation?.products

    this.data.MarketplaceVariations.map(variate => {
      let prepareDataVariate = variate.prepareSave()
      let foundProduct = _.find(prepareProducts, {product_id: variate.getId()})

      // let indexColor =_.findIndex(foundProduct?.property_values, {property_name: 'Color'})
      // foundProduct.property_values[indexColor].values[0] = prepareDataVariate.color
      //
      // let indexSize =_.findIndex(foundProduct?.property_values, {property_name: 'Size'})
      // foundProduct.property_values[indexSize].values[0] = prepareDataVariate.size

      foundProduct.offerings[0].price.amount = prepareDataVariate.price

      foundProduct.offerings[0].is_enabled = prepareDataVariate.visible

      foundProduct.offerings[0].quantity = prepareDataVariate.quantity

    })

    data['variation_payload'] = prepareVariation
  }


  return data

}
