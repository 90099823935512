<template>
 <div v-if="itemsNumber > 1" class="pagination"
      v-bind:class="{'disabled': loading}"
 >
    <template v-if="itemsNumber < 9">
      <template v-for="item in itemsNumber">
        <div class="pagination__item" :key="item"
             v-bind:class="{'active': item === activePage}"
             @click="$emit('paginate', item)"
        >
          {{item}}
        </div>
      </template>

    </template>
   <template v-else>
     <div class="pagination__item"
          v-bind:class="{'disabled': activePage === 1}"
          @click="$emit('paginate', 1)"
     >&#10094;&#10094;</div>
     <div class="pagination__item"
          v-bind:class="{'disabled': activePage === 1}"
          @click="$emit('paginate', activePage - 1)"
     >&#10094;</div>
     <template v-for="item in itemsNumber">
       <template v-if="activePage <= 4 && item <= 5">
         <div class="pagination__item" :key="item"
              v-bind:class="{'active': item === activePage}"
              @click="$emit('paginate', item)"
         >
           {{item}}
         </div>
       </template>
       <template v-else-if="(activePage + 3) >= itemsNumber && (item + 4 >= itemsNumber)">
         <div class="pagination__item" :key="item"
              v-bind:class="{'active': item === activePage}"
              @click="$emit('paginate', item)"
         >
           {{item}}
         </div>
       </template>
       <template v-else-if="(item + 2 >= activePage) && (item - 2 <= activePage)">
         <div class="pagination__item" :key="item"
              v-bind:class="{'active': item === activePage}"
              @click="$emit('paginate', item)"
         >
           {{item}}
         </div>
       </template>

     </template>
     <div class="pagination__item"
          v-bind:class="{'disabled': activePage === itemsNumber}"
          @click="$emit('paginate', activePage + 1)"
     >&#10095;</div>
     <div class="pagination__item"
          v-bind:class="{'disabled': activePage === itemsNumber}"
          @click="$emit('paginate', itemsNumber)"
     >&#10095;&#10095;</div>

   </template>

 </div>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    count: Number,
    perPage: Number,
    loading: Boolean,
  },
  computed: {
    itemsNumber() {
      if (!this.count || this.count === 0) {
        return 0
      }

      return Math.ceil(this.count / this.perPage)
    },
    activePage() {
      if (this.$route.query.page && this.$route.query.pagination === 'true') {
        return +this.$route.query.page
      }

      return 1
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../scss/colors";
@import "../../../scss/mixins/mixins";

.pagination {
  width: max-content;
  max-width: 100%;
  margin-left: auto;
  display: flex;
  align-items: center;

  //@media (max-width: 1120px) {
  //  margin-bottom: 1rem;
  //}

  &.disabled {
    .pagination__item:not(.active) {
      pointer-events: none;
      background-color: #e7e7e7;
      color: $silver;
    }
  }

  &__item {
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    line-height: 32px;
    color: #232323;
    text-align: center;
    width: 50px;
    height: 32px;
    border-left: 1px solid $borderBrown;
    border-top: 1px solid $borderBrown;
    border-bottom: 1px solid $borderBrown;
    transition: background-color 0.3s;
    background-color: $mainBg;

    &:last-child {
      border-right: 1px solid $borderBrown;
    }

    &:hover {
      background-color: $borderBrownOpacity02;
    }

    &.active {
      pointer-events: none;
      background-color: $borderBrown;
      cursor: default;
    }

    &.disabled {
      pointer-events: none;
      background-color: #e7e7e7;
      color: $silver;
    }

    @media (max-width: 550px) {
      width: 35px;
      font-size: 12px;
    }
  }
}
</style>