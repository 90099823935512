<template>
  <div class="order-create__product custom-row"
       @mouseover="isHovering2 = canRemoveProduct"
       @mouseout="isHovering2 = false"
       :class="{hover: isHovering2}"
  >
    <div class="custom-col mb-0">
      <div class="order-create__product-col custom-col  custom-col--md-100 position-relative d-flex">
        <div class="order-create__product-delete" v-if="canRemoveProduct">
					<span @click="$emit('removeProduct')">
						<DeleteProductBtn/>
					</span>
        </div>

        <slot name="fields"></slot>

      </div>
    </div>

<!--    <div class="order-create__product-col custom-col custom-col&#45;&#45;16 custom-col&#45;&#45;md-0 mb-0"></div>-->
  </div>
</template>

<script>

import DeleteProductBtn from "@/components/coreComponents/Buttons/DeleteProductBtn/DeleteProductBtn";

export default {
  name: "DigitalProductItemWrap",

  components: {
    DeleteProductBtn,
  },

  props: {
    canRemoveProduct: {
      type: Boolean,
      default: true
    },
  },

  data() {
    return {
      isHovering:false,
      isHovering2:false,

    }
  },

}
</script>

<style scoped>
.order-create__product-delete {
  left: -30px;
}
</style>