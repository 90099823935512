import {checkValid} from "@/components/globalModels/functions/ModelsValidate";
import {Files} from "@/components/globalModels/Files";


export function DigitalProduct() {

  this.checkValid = checkValid

  this.data = {
    fileModelId: null,
    name: '',
    price: '',
    
    isFile: true,
    File: new Files({
      maxCountFiles: 1,
      maxSizeFiles: 20
    }),
    fileUrl: '',

    sampleModelId: null,
    isSampleFile: true,
    SampleFile: new Files({
      maxCountFiles: 1,
      maxSizeFiles: 20
    }),
    sampleFileUrl: '',

    download: '',
    sortOrder: '',
  }

  this.validation = {
    name: false,
    price: false,
    fileUrl: false,
    sampleFileUrl: false,
    download: false,
    sortOrder: false,
  }

  this.validationTranslate = {
    name: '',
    price: '',
    fileUrl: '',
    sampleFileUrl: '',
    download: '',
    sortOrder: '',
  }

  this.validationTxt = {
    name: false,
    price: false,
    fileUrl: false,
    sampleFileUrl: false,
    download: false,
    sortOrder: false,
  }

  /**
   * Getters
   */

  this.getFileModelId = () => {
    return this.data.fileModelId
  }
  this.getName = () => {
    return this.data.name
  }
  this.getPrice = () => {
    return this.data.price
  }
  this.getIsFile = () => {
    return this.data.isFile
  }
  this.getFileUrl = () => {
    return this.data.fileUrl
  }
  this.getIsSampleFile = () => {
    return this.data.isSampleFile
  }
  this.getSampleModelId = () => {
    return this.data.sampleModelId
  }
  this.getSampleFileUrl = () => {
    return this.data.sampleFileUrl
  }
  this.getDownload = () => {
    return this.data.download
  }
  this.getSortOrder = () => {
    return this.data.sortOrder
  }


  /**
   * Setters
   */

  this.setFileModelId = (val) => {
    return this.data.fileModelId = val
  }
  this.setName = (val) => {
    return this.data.name = val
  }
  this.setPrice = (val) => {
    return this.data.price = val
  }
  this.setIsFile = (val) => {
    return this.data.isFile = val
  }
  this.setFileUrl = (val) => {
    return this.data.fileUrl = val
  }
  this.setSampleModelId = (val) => {
    return this.data.sampleModelId = val
  }
  this.setIsSampleFile = (val) => {
    return this.data.isSampleFile = val
  }
  this.setSampleFileUrl = (val) => {
    return this.data.sampleFileUrl = val
  }
  this.setDownload = (val) => {
    return this.data.download = val
  }
  this.setSortOrder = (val) => {
    return this.data.sortOrder = val
  }

  /**
   * Global Setters
   */

  DigitalProduct.prototype.setItem = function (item) {
    this.setName(item?.name ? item.name : '')
    this.setPrice(item?.price ? item.price : '')
    this.setIsFile(item?.isFile ? item.isFile : true)
    this.setFileUrl(item?.fileUrl ? item.fileUrl : '')
    this.setIsSampleFile(item?.isSampleFile ? item.isSampleFile : true)
    this.setSampleFileUrl(item?.sampleFileUrl ? item.sampleFileUrl : '')
    this.setDownload(item?.download ? item.download : '')
    this.setSortOrder(item?.sortOrder ? item.sortOrder : '')
  }

  /**
   * Validations
   */

  DigitalProduct.prototype.firstValidationAdmin = function () {

    let validationItems = {
      name: this.getName(),
      price: this.getPrice(),
      download: this.getDownload(),
      sortOrder: this.getSortOrder(),
    }

    let validationOptions = {
      name: {type: ['empty']},
      price: {type: ['empty']},
      download: {type: ['empty']},
      sortOrder: {type: ['empty']},
    }

    let valid = true
    if(this.getIsFile() && !this.data.File.firstValidation()) {
      valid = false
    }
    if(this.getIsSampleFile() && !this.data.SampleFile.firstValidation()) {
      valid = false
    }

    console.log(33);
    console.log(valid);

    return this.checkValid(validationItems, validationOptions) && valid
  }

  /**
   * Prepare Data
   */

  DigitalProduct.prototype.prepareSave = function () {

    return  {

    }

  }
}