<template>
  <div>
    <treeselect
        class="tree-select"
        :name="'default-tree-select-' + id"
        :multiple="multiple"
        :clearable="clearable"
        :searchable="searchable"
        :disabled="disabled"
        :open-on-click="openOnClick"
        :open-on-focus="openOnFocus"
        :clear-on-select="clearOnSelect"
        :close-on-select="closeOnSelect"
        :always-open="alwaysOpen"
        :append-to-body="appendToBody"
        :options="options"
        :limit="limit"
        :max-height="maxHeight"
        :value="value"
        @input="changeTreeSelect"
    >

      <div slot="value-label" slot-scope="{ node }">
        <template v-if="optionType === ''">
          {{ node.label }}
        </template>
        <template v-if="optionType === 'labelName'">
          {{ node.raw.name }}
        </template>
      </div>

      <label slot="option-label" slot-scope="{ node, shouldShowCount, count, labelClassName }" :class="labelClassName">
        <template v-if="optionType === ''">
          {{ node.label }}
        </template>
        <template v-if="optionType === 'labelName'">
          {{ node.raw.name }}
        </template>
      </label>

    </treeselect>
  </div>
</template>

<script>
// import the component
import Treeselect from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  name: "TreeSelect",

  components: {
    Treeselect,
  },

  props: {
    options: {
      type: Array,
      default: () => {return []}
    },
    optionType: {
      type: String,
      default: '',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    openOnClick: {
      type: Boolean,
      default: true,
    },
    openOnFocus: {
      type: Boolean,
      default: false,
    },
    clearOnSelect: {
      type: Boolean,
      default: false,
    },
    closeOnSelect: {
      type: Boolean,
      default: true,
    },
    alwaysOpen: {
      type: Boolean,
      default: false,
    },
    appendToBody: {
      type: Boolean,
      default: false,
    },
    limit: {
      type: Number,
      default: Infinity,
    },
    maxHeight: {
      type: Number,
      default: 300,
    },
    value: {
      type: [String, Array],
      default: '',
    },
  },

  data() {
    return {
      id: '',
    }
  },

  mounted () {
    this.id = this._uid
  },

  methods: {
    changeTreeSelect(val) {
      this.$emit('change', val)
    }
  },

}
</script>

<style scoped>
.tree-select >>> .vue-treeselect__control {
  padding-left: 16px;
  min-height: 42px;
  background: #F8F4EE;
  border: 1px solid #BBAD9C;
  box-sizing: border-box;
  border-radius: 5px;
}

.tree-select >>> .vue-treeselect__placeholder {
  font-size: 14px;
  line-height: 40px;
  color: #BBAD9C;
}

.tree-select >>> .vue-treeselect__control-arrow path{
  fill: transparent;
}

.tree-select >>> .vue-treeselect__control-arrow {
  width: 14px;
  background: url("../../../../assets/img/UI-group/arrow-bottom.svg") center center no-repeat;
}

.tree-select >>> .vue-treeselect__multi-value-item {
  background: #8F7A61;
  color: white;
  font-size: 14px;
}

.tree-select >>> .vue-treeselect__value-remove {
  color: white;
}

.tree-select >>> .vue-treeselect--focused:not(.vue-treeselect--open) .vue-treeselect__control {
  box-shadow: initial;
}

.tree-select >>> .vue-treeselect__checkbox {
  border-color: #8F7A61;
  background: #8F7A61;
  width: 16px;
  height: 16px;
}

.tree-select >>> .vue-treeselect__check-mark {
  /*width: 15px;*/
  /*height: 15px;*/
  margin-top: 2px;
  margin-left: 2px;
}

.tree-select >>> .vue-treeselect__minus-mark {
  margin-top: 2px;
  margin-left: 2px;
}

</style>