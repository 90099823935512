import {checkValid} from "@/components/globalModels/functions/ModelsValidate";
import _ from 'lodash'


export function MarketplaceVariations() {

  this.checkValid = checkValid

  this.data = {
    id: null,
    color: '',
    size: '',
    variationName: '',
    variationValue: '',
    variation2Name: '',
    variation2Value: '',
    price: '',
    divisor: '',
    quantity: '',
    visible: false,
  }

  this.validation = {
    id: false,
    color: false,
    size: false,
    price: false,
    quantity: false,
    visible: false,
  }

  this.validationTranslate = {
    id: '',
    color: '',
    size: '',
    price: '',
    quantity: '',
    visible: '',
  }

  this.validationTxt = {
    id: false,
    color: false,
    size: false,
    price: false,
    quantity: false,
    visible: false,
  }

  /**
   * Getters
   */

  this.getId = () => {
    return this.data.id
  }
  this.getColor = () => {
    return this.data.color
  }
  this.getSize = () => {
    return this.data.size
  }
  this.getVariationName = () => {
    return this.data.variationName
  }
  this.getVariationValue = () => {
    return this.data.variationValue
  }
  this.getVariation2Name = () => {
    return this.data.variation2Name
  }
  this.getVariation2Value = () => {
    return this.data.variation2Value
  }
  this.getPrice = () => {
    return this.data.price
  }
  this.getDivisor = () => {
    return this.data.divisor
  }
  this.getQuantity = () => {
    return this.data.quantity
  }
  this.getVisible = () => {
    return this.data.visible
  }

  /**
   * Setters
   */

  this.setId = (val) => {
    return this.data.id = val
  }
  this.setColor = (val) => {
    return this.data.color = val
  }
  this.setSize = (val) => {
    return this.data.size = val
  }
  this.setVariationName = (val) => {
    return this.data.variationName = val
  }
  this.setVariationValue = (val) => {
    return this.data.variationValue = val
  }
  this.setVariation2Name = (val) => {
    return this.data.variation2Name = val
  }
  this.setVariation2Value = (val) => {
    return this.data.variation2Value = val
  }
  this.setPrice = (val) => {
    return this.data.price = val
  }
  this.setDivisor = (val) => {
    return this.data.divisor = val
  }
  this.setQuantity = (val) => {
    return this.data.quantity = val
  }
  this.setVisible = (val) => {
    return this.data.visible = val
  }

}


/**
 * Global Setters
 */

MarketplaceVariations.prototype.setItem = function (item) {
  this.setId(item.product_id)
  // this.setColor(_.first(_.find(item?.property_values, {property_name: 'Color'})?.values))
  // this.setSize(_.first(_.find(item?.property_values, {property_name: 'Size'})?.values))
  if(_.has(item?.property_values, '0')) {
    this.setVariationName(item.property_values[0]?.property_name)
    this.setVariationValue(_.first(item.property_values[0]?.values))
  }
  if(_.has(item?.property_values, '1')) {
    this.setVariation2Name(item.property_values[1]?.property_name)
    this.setVariation2Value(_.first(item.property_values[1]?.values))
  }
  this.setPrice(parseFloat(_.first(item.offerings)?.price?.amount / _.first(item.offerings)?.price?.divisor).toFixed(2))
  this.setDivisor(_.first(item.offerings)?.price?.divisor)
  this.setQuantity(_.first(item.offerings)?.quantity)
  this.setVisible(_.first(item.offerings)?.is_enabled)
}


/**
 * Functions
 */


MarketplaceVariations.prototype.firstValidation = function () {

  let validationItems = {
    color: this.getColor(),
    // size: this.getSize(),
    // price: this.getPrice(),
    quantity: this.getQuantity(),
  }

  let validationOptions = {
    // color: {type: ['empty']},
    // size: {type: ['empty']},
    price: {type: ['not-zero','empty']},
    quantity: {type: ['not-zero','empty']},
  }

  return this.checkValid(validationItems, validationOptions)
}

/**
 * Prepare Data
 */

MarketplaceVariations.prototype.prepareSave = function () {

  console.log(121212);
  console.log(this.getPrice());
  console.log(this.getDivisor());
  return {
    'id': this.getId(),
    'color': this.getColor(),
    'size': this.getSize(),
    'price': Number(this.getPrice()) * this.getDivisor(),
    'quantity': Number(this.getQuantity()),
    'visible': this.getVisible(),
  }

}
