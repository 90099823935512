<template>
  <div class="fragment">
    <StepContent
        :MP="MP"
        :categories="categories"
    />

    <div class="cus-section cus-section--btn">
      <div class="cus-section__footer order-create__footer">
        <div class="order-create__footer-link"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['common_cancel'])"></div>
          <router-link :to="$store.getters.GET_PATHS.marketplaceProducts"
                       class="site-link site-link--alt"
          >
            {{ $t('common_cancel.localization_value.value') }}
          </router-link>
        </div>


        <div class="order-create__footer-btn ml-auto"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['common_create'])"></div>
<!--          <MainButton-->
<!--              class="order-create__footer-btn-i secondary"-->
<!--              :value="$t('common_SaveInDrafts.localization_value.value')"-->
<!--              :tooltip="true"-->
<!--              @click.native="$emit('saveDraft', true)"-->
<!--              v-bind:class="{'disabled-btn' : $store.getters.getEtsyListingsBtn}"-->
<!--          >-->
<!--            <template slot="tooltip">-->
<!--              <p>-->
<!--                <b>{{ $t('common_drafts.localization_value.value') }}</b>-->
<!--              </p>-->
<!--              <p>-->
<!--                {{ $t('common_savingInDrafts.localization_value.value') }}-->
<!--              </p>-->
<!--            </template>-->
<!--          </MainButton>-->

          <MainButton
              class="order-create__footer-btn-i"
              :value="$t('common_create.localization_value.value')"
              @click.native="$emit('save')"
              v-bind:class="{'disabled-btn' : $store.getters.getMarketplaceProductsBtn}"
          />
        </div>
      </div>
    </div>
  </div>


</template>

<script>


  import StepContent from "@/components/modules/MarketplaceProductsModule/components/chunks/StepContent/StepContent";
  import MainButton from "@/components/UI/buttons/MainButton/MainButton";

  export default {
    name: "MarketplaceProductsCreationUser",

    components: {
      MainButton,
      StepContent
    },

    mixins: [],

    props: {
      MP: Object,
      categories: Array,
    },

    data() {
      return {
      }
    },

    methods:{

    }
  }
</script>

<style lang="scss">

  .hide-upc{
    opacity: 0.5;
    pointer-events: none;
  }

  .product-progress-steps{
    max-width: 625px;
  }

</style>
