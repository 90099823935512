<template>
  <div class="fragment">
    <div class="cus-section digital-product">

      <div class="custom-row">
        <div class="custom-col custom-col--sxl-66 custom-col--md-100">
          <div class="section-label large"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['etsyListing_digitalProductType'])"></div>
            {{ $t('etsyListing_digitalProductType.localization_value.value') }}
          </div>

          <div class="custom-row">
            <div class="custom-col custom-col--16 custom-col--sxl-33 custom-col--sm-50 custom-col--es-100 mb-0"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['etsyListing_standardProduct'])"></div>
              <LargeRadio
                  class="soft-text mw-100"
                  :label="$t('etsyListing_standardProduct.localization_value.value')"
                  :name="'params-digital'"
                  @change="() => {MP.data.isDigitalProduct = false}"
                  :checked="!MP.data.isDigitalProduct"
                  :value="MP.data.isDigitalProduct"
              />
            </div>
            <div class="custom-col custom-col--16 custom-col--sxl-33 custom-col--sm-50 custom-col--es-100 mb-0"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}"
                 :key="MP.data.isDigitalProduct"
            >
              <div class="admin-edit" @click="editTranslate(['etsyListing_digitalProduct'])"></div>
              <LargeRadio
                  class="soft-text mw-100"
                  :label="$t('etsyListing_digitalProduct.localization_value.value')"
                  :name="'params-digital'"
                  @change="() => {MP.data.isDigitalProduct = true}"
                  :checked="MP.data.isDigitalProduct"
                  :value="MP.data.isDigitalProduct"
              />
            </div>
          </div>
        </div>
      </div>

      <template v-if="MP.data.isDigitalProduct">

        <div class="section-label d-flex">
          Links
          <TooltipTextHelperPlugin>
            <template slot="text">
              <TooltipBtn/>
            </template>
            <template slot="title">
              {{$t('etsyListing_digitalFiles.localization_value.value')}}
            </template>
            <template slot="paragraph">
              .pdf,.zip,.bmp,.stl,.gif,.xml,.txt,.png,.jpeg.
            </template>
          </TooltipTextHelperPlugin>
        </div>


        <DigitalProductItemWrap
            v-for="(product, indexProduct) in MP.data.DigitalProducts"
            :key="indexProduct"
            @removeProduct="removeProduct(indexProduct, product)"
        >
          <template slot="fields">
            <div class="custom-row w-100">
              <div class="custom-col custom-col--25 custom-col--md-50"
                   v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['etsyListing_digitalName'])"></div>
                <DefaultInput
                    :label="$t('etsyListing_digitalName.localization_value.value')"
                    v-bind:class="{'ui-no-valid': product.validation.name}"
                    :error="product.validation.name"
                    :errorTxt="$t(`${product.validationTranslate.name}.localization_value.value`)"
                    v-model="product.data.name"
                />
              </div>
              <div class="custom-col custom-col--25 custom-col--md-50"
                   v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['etsyListing_digitalPrice'])"></div>
                <DefaultInput
                    :label="$t('etsyListing_digitalPrice.localization_value.value')"
                    v-bind:class="{'ui-no-valid': product.validation.price}"
                    :error="product.validation.price"
                    :errorTxt="$t(`${product.validationTranslate.price}.localization_value.value`)"
                    v-model="product.data.price"
                />
              </div>
              <div class="custom-col custom-col--25 custom-col--md-50"
                   v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['etsyListing_digitalDownload'])"></div>
                <DefaultInput
                    :label="$t('etsyListing_digitalDownload.localization_value.value')"
                    v-bind:class="{'ui-no-valid': product.validation.download}"
                    :error="product.validation.download"
                    :errorTxt="$t(`${product.validationTranslate.download}.localization_value.value`)"
                    v-model="product.data.download"
                />
              </div>
              <div class="custom-col custom-col--25 custom-col--md-50"
                   v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['etsyListing_digitalSort'])"></div>
                <DefaultInput
                    :label="$t('etsyListing_digitalSort.localization_value.value')"
                    v-bind:class="{'ui-no-valid': product.validation.sortOrder}"
                    :error="product.validation.sortOrder"
                    :errorTxt="$t(`${product.validationTranslate.sortOrder}.localization_value.value`)"
                    v-model="product.data.sortOrder"
                />
              </div>
              <div class="custom-col custom-col--50 custom-col--md-50 custom-col--xs-100 mb-0">
<!--                <div class="d-flex align-center mb-2">-->
<!--                  <RadioDefault-->
<!--                      :label="$t('etsyListing_digitalFile.localization_value.value')"-->
<!--                      :name="'digitalProductRadio' + indexProduct"-->
<!--                      :value="product.data.isFile"-->
<!--                      :disabled="Object.keys(product.data.File.data.downloadFiles).length > 0"-->
<!--                      @change.native="product.data.isFile = true"-->
<!--                  />-->
<!--                  <RadioDefault-->
<!--                      :label="$t('etsyListing_digitalUrl.localization_value.value')"-->
<!--                      :name="'digitalProductRadio' + indexProduct"-->
<!--                      :value="!product.data.isFile"-->
<!--                      :disabled="Object.keys(product.data.File.data.downloadFiles).length > 0"-->
<!--                      @change.native="product.data.isFile = false"-->
<!--                  />-->
<!--                </div>-->

                <template v-if="product.data.isFile">
                  <div class="custom-row w-100">
                    <div class="custom-col mb-2 mt-2">File</div>
                    <div class="custom-col position-relative mb-0"
                         v-if="product.data.File.data.maxCountFiles - Object.keys(product.data.File.data.downloadFiles).length > 0"
                    >
                      <DropZone class="drop-zone-bg"
                                :parentFiles="product.data.File.data.files"
                                @changeImg="(files, maxSizeError) => changeProductFile(files, maxSizeError, product)"
                                :maxSize="product.data.File.data.maxSizeFiles"
                                :maxCount="product.data.File.data.maxCountFiles - Object.keys(product.data.File.data.downloadFiles).length"
                                :accept="'image/x-png,image/jpeg,application/pdf,application/epub+zip,application/zip,application/msword,application/octet-stream,image/bmp,model/stl,image/gif,text/plain,text/xml'"
                      />
                      <div class="error-field ml-3" v-if="product.data.File.validation.files">
                        {{$t('common_fileIsRequired.localization_value.value')}}
                      </div>
                    </div>

                    <div class="custom-row product-img-row ml-4 mt-4"
                         v-if="Object.keys(product.data.File.data.downloadFiles).length > 0"
                    >
                      <div class="custom-col"
                           v-for="(file, indexFile) in product.data.File.data.downloadFiles"
                           :key="indexFile + 'f1'"
                      >
                        <div class="position-relative justify-content-center">
                          <div class="site-document__remove" style="right: -25px;"
                               @click="removeFile(product.data.File, indexFile, parseInt(file.id))">
                            <CloseIcon/>
                          </div>
                          <div class="site-document__img">
                            <img src="/img/finance-group/list-no-image.svg" alt="img" class="cursor-initial">
                          </div>
                          <div style="font-size: 14px;">
                            {{file.fileName}}
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </template>

                <div v-else
                     class="custom-col custom-col--50"
                     v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['etsyListing_digitalUrl'])"></div>
                  <DefaultInput
                      :label="$t('etsyListing_digitalUrl.localization_value.value')"
                      v-bind:class="{'ui-no-valid': product.validation.fileUrl}"
                      :error="product.validation.fileUrl"
                      :errorTxt="$t(`${product.validationTranslate.fileUrl}.localization_value.value`)"
                      v-model="product.data.fileUrl"
                  />
                </div>
              </div>


              <div class="custom-col custom-col--50 custom-col--md-50 custom-col--xs-100 mb-0">
<!--                <div class="d-flex align-center mb-2">-->
<!--                  <RadioDefault-->
<!--                      :label="$t('etsyListing_digitalFile.localization_value.value')"-->
<!--                      :name="'digitalProductSampleRadio' + indexProduct"-->
<!--                      :value="product.data.isSampleFile"-->
<!--                      :disabled="Object.keys(product.data.SampleFile.data.downloadFiles).length > 0"-->
<!--                      @change.native="product.data.isSampleFile = true"-->
<!--                  />-->
<!--                  <RadioDefault-->
<!--                      :label="$t('etsyListing_digitalUrl.localization_value.value')"-->
<!--                      :name="'digitalProductSampleRadio' + indexProduct"-->
<!--                      :value="!product.data.isSampleFile"-->
<!--                      :disabled="Object.keys(product.data.SampleFile.data.downloadFiles).length > 0"-->
<!--                      @change.native="product.data.isSampleFile = false"-->
<!--                  />-->
<!--                </div>-->

                <template v-if="product.data.isSampleFile">
                  <div class="custom-row w-100">
                    <div class="custom-col mb-2 mt-2">File sample</div>
                    <div class="custom-col position-relative mb-0"
                         v-if="product.data.SampleFile.data.maxCountFiles - Object.keys(product.data.SampleFile.data.downloadFiles).length > 0"

                    >
                      <DropZone class="drop-zone-bg"
                                :parentFiles="product.data.SampleFile.data.files"
                                @changeImg="(files, maxSizeError) => changeProductSampleFile(files, maxSizeError, product)"
                                :maxSize="product.data.SampleFile.data.maxSizeFiles"
                                :maxCount="product.data.SampleFile.data.maxCountFiles - Object.keys(product.data.SampleFile.data.downloadFiles).length"
                                :accept="'image/x-png,image/jpeg,application/pdf,application/epub+zip,application/zip,application/msword,application/octet-stream,image/bmp,model/stl,image/gif,text/plain,text/xml'"
                      />
                      <div class="error-field ml-3" v-if="product.data.SampleFile.validation.files">
                        {{$t('common_fileIsRequired.localization_value.value')}}
                      </div>
                    </div>

                    <div class="custom-row product-img-row ml-4 mt-4"
                         v-if="Object.keys(product.data.SampleFile.data.downloadFiles).length > 0"
                    >
                      <div class="custom-col"
                           v-for="(file, indexFile) in product.data.SampleFile.data.downloadFiles"
                           :key="indexFile + 'f1'"
                      >
                        <div class="position-relative justify-content-center">
                          <div class="site-document__remove" style="right: -25px;"
                               @click="removeFile(product.data.SampleFile, indexFile, parseInt(file.id))">
                            <CloseIcon/>
                          </div>
                          <div class="site-document__img">
                            <img src="/img/finance-group/list-no-image.svg" alt="img" class="cursor-initial">
                          </div>
                          <div style="font-size: 14px;">
                            {{file.fileName}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>

                <div v-else
                     class="custom-col custom-col--50"
                     v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['etsyListing_digitalUrl'])"></div>
                  <DefaultInput
                      :label="$t('etsyListing_digitalUrl.localization_value.value')"
                      v-bind:class="{'ui-no-valid': product.validation.sampleFileUrl}"
                      :error="product.validation.sampleFileUrl"
                      :errorTxt="$t(`${product.validationTranslate.sampleFileUrl}.localization_value.value`)"
                      v-model="product.data.sampleFileUrl"
                  />
                </div>
              </div>
            </div>
          </template>
        </DigitalProductItemWrap>

        <div class="custom-row">
          <div class="custom-col mb-4">
            <div class="order-create__product-btn add-product-btn-wrap"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_AddProduct',])"></div>
              <span class="order-create__product-btn-i add-product-btn site-link"
                    @click="addProduct">
            + {{$t('common_AddProduct.localization_value.value')}}
            </span>
            </div>
          </div>
        </div>


        <div class="section-label d-flex">
          Samples

          <TooltipTextHelperPlugin>
            <template slot="text">
              <TooltipBtn/>
            </template>
            <template slot="title">
              {{$t('etsyListing_digitalFiles.localization_value.value')}}
            </template>
            <template slot="paragraph">
              .pdf,.zip,.bmp,.stl,.gif,.xml,.txt,.png,.jpeg.
            </template>
          </TooltipTextHelperPlugin>
        </div>

        <DigitalProductItemWrap
            v-for="(sample, indexSample) in MP.data.DigitalSampleProducts"
            :key="indexSample + 's'"
            @removeProduct="removeProduct(indexSample, sample, true)"
        >
          <template slot="fields">
            <div class="custom-row w-100">
              <div class="custom-col custom-col--25 custom-col--md-50"
                   v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['etsyListing_digitalName'])"></div>
                <DefaultInput
                    :label="$t('etsyListing_digitalName.localization_value.value')"
                    v-bind:class="{'ui-no-valid': sample.validation.name}"
                    :error="sample.validation.name"
                    :errorTxt="$t(`${sample.validationTranslate.name}.localization_value.value`)"
                    v-model="sample.data.name"
                />
              </div>

              <div class="custom-col custom-col--25 custom-col--md-50"
                   v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['etsyListing_digitalSort'])"></div>
                <DefaultInput
                    :label="$t('etsyListing_digitalSort.localization_value.value')"
                    v-bind:class="{'ui-no-valid': sample.validation.sortOrder}"
                    :error="sample.validation.sortOrder"
                    :errorTxt="$t(`${sample.validationTranslate.sortOrder}.localization_value.value`)"
                    v-model="sample.data.sortOrder"
                />
              </div>
              <div class="custom-col custom-col--25 custom-col--md-50 mb-0"></div>

              <div class="custom-col custom-col--50 custom-col--md-50 custom-col--xs-100 mb-0">
<!--                <div class="d-flex align-center mb-2">-->
<!--                  <RadioDefault-->
<!--                      :label="$t('etsyListing_digitalFile.localization_value.value')"-->
<!--                      :name="'sampleFileRadio' + indexSample"-->
<!--                      :value="sample.data.isFile"-->
<!--                      :disabled="Object.keys(sample.data.File.data.downloadFiles).length > 0"-->
<!--                      @change.native="sample.data.isFile = true"-->
<!--                  />-->
<!--                  <RadioDefault-->
<!--                      :label="$t('etsyListing_digitalUrl.localization_value.value')"-->
<!--                      :name="'sampleFileRadio' + indexSample"-->
<!--                      :value="!sample.data.isFile"-->
<!--                      :disabled="Object.keys(sample.data.File.data.downloadFiles).length > 0"-->
<!--                      @change.native="sample.data.isFile = false"-->
<!--                  />-->
<!--                </div>-->

                <template v-if="sample.data.isFile">
                  <div class="custom-row w-100">
                    <div class="custom-col mb-2 mt-2">File</div>
                    <div class="custom-col position-relative mb-2"
                         v-if="sample.data.File.data.maxCountFiles - Object.keys(sample.data.File.data.downloadFiles).length > 0"
                    >
                      <DropZone class="drop-zone-bg"
                                :parentFiles="sample.data.File.data.files"
                                @changeImg="(files, maxSizeError) => changeProductFile(files, maxSizeError, sample)"
                                :maxSize="sample.data.File.data.maxSizeFiles"
                                :maxCount="sample.data.File.data.maxCountFiles - Object.keys(sample.data.File.data.downloadFiles).length"
                                :accept="'image/x-png,image/jpeg,application/pdf,application/epub+zip,application/zip,application/msword,application/octet-stream,image/bmp,model/stl,image/gif,text/plain,text/xml'"
                      />
                      <div class="error-field ml-3" v-if="sample.data.File.validation.files">
                        {{$t('common_fileIsRequired.localization_value.value')}}
                      </div>
                    </div>

                    <div class="custom-row product-img-row ml-4 mt-4"
                         v-if="Object.keys(sample.data.File.data.downloadFiles).length > 0"
                    >
                      <div class="custom-col"
                           v-for="(file, indexFile) in sample.data.File.data.downloadFiles"
                           :key="indexFile + 'f1'"
                      >
                        <div class="position-relative justify-content-center">
                          <div class="site-document__remove" style="right: -25px;"
                               @click="removeFile(sample.data.File, indexFile, parseInt(file.id))">
                            <CloseIcon/>
                          </div>
                          <div class="site-document__img">
                            <img src="/img/finance-group/list-no-image.svg" alt="img" class="cursor-initial">
                          </div>
                          <div style="font-size: 14px;">
                            {{file.fileName}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>

                <div v-else
                     class="custom-col custom-col--50"
                     v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['etsyListing_digitalUrl'])"></div>
                  <DefaultInput
                      :label="$t('etsyListing_digitalUrl.localization_value.value')"
                      v-bind:class="{'ui-no-valid': sample.validation.fileUrl}"
                      :error="sample.validation.fileUrl"
                      :errorTxt="$t(`${sample.validationTranslate.fileUrl}.localization_value.value`)"
                      v-model="sample.data.fileUrl"
                  />
                </div>
              </div>
            </div>
          </template>
        </DigitalProductItemWrap>


        <div class="custom-row">
          <div class="custom-col mb-4">
            <div class="order-create__product-btn add-product-btn-wrap"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_AddProduct',])"></div>
              <span class="order-create__product-btn-i add-product-btn site-link"
                    @click="addSample">
            + {{$t('common_AddProduct.localization_value.value')}}
            </span>
            </div>
          </div>
        </div>
      </template>


      <div class="custom-row">
        <div class="custom-col font-weight-bold policy"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['etsyListing_digitalPolicy','etsyListing_digitalPolicy2','etsyListing_digitalPolicy3',])"></div>
<!--          By adding files to this listing, you guarantee that you have rights to the content.-->
<!--          SKLAD USA may remove content per our Intellectual Property Policy, at which point buyers may not be able to access purchased files.-->
<!--          See our Terms for more information.-->
          <span v-html="$t('etsyListing_digitalPolicy.localization_value.value')"></span>
          <span v-html="$t('etsyListing_digitalPolicy2.localization_value.value')"></span>
          <span v-html="$t('etsyListing_digitalPolicy3.localization_value.value')"></span>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import LargeRadio from "@/components/UI/radiobuttons/LargeRadio/LargeRadio";
import DefaultInput from "@/components/UI/inputs/DefaultInput/DefaultInput";
// import RadioDefault from "@/components/UI/radiobuttons/RadioDefault/RadioDefault";
import DropZone from "@/components/coreComponents/DropZone/DropZone";
import DigitalProductItemWrap
  from "@/components/modules/MarketplaceProductsModule/components/chunks/DigitalProductItemWrap/DigitalProductItemWrap";
import CloseIcon from '../../../../../../../public/img/modal-group/close-icon.svg?inline'
import TooltipTextHelperPlugin from "@/components/coreComponents/TooltipTextHelperPlugin/TooltipTextHelperPlugin";
import TooltipBtn from "@/components/UI/tooltips/TooltipBtn/TooltipBtn";
export default {
  name: "DigitalProduct",

  components: {
    TooltipBtn,
    TooltipTextHelperPlugin,
    DigitalProductItemWrap,
    DropZone,
    // RadioDefault,
    DefaultInput,
    LargeRadio,
    CloseIcon,
  },

  props: {
    MP: Object
  },

  methods: {
    changeProductFile(files, maxSizeError, item) {
      item.data.File.setFiles(files)
      item.data.File.setFilesMaxSizeError(maxSizeError)
    },

    changeProductSampleFile(files, maxSizeError, product) {
      product.data.SampleFile.setFiles(files)
      product.data.SampleFile.setFilesMaxSizeError(maxSizeError)
    },

    addProduct() {
      this.MP.addDigitalProduct()
    },

    addSample() {
      this.MP.addDigitalSample()
    },

    removeProduct(index, item, isSample = false) {

      if(!item.data.fileModelId) {
        if(isSample) {
          this.MP.removeDigitalSample(index)
        } else {
          this.MP.removeDigitalProduct(index)
        }
        return
      }

      let text = {
        title: 'common_AreYouSure',
        txt: '',
        yes: 'common_confirmDelete',
        no: 'common_confirmNo'
      }

      let confirm = () => {

        this.$store.dispatch('deleteDigitalProduct', item.getFileModelId()).then((response) => {
          switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

              if(isSample) {
                this.MP.removeDigitalSample(index)
              } else {
                this.MP.removeDigitalProduct(index)
              }

              this.openNotify('success', 'common_notificationRecordDeleted')
              break
            }
              /**
               * Validation Error
               */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
              /**
               * Undefined Error
               */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        })

        this.$store.commit('setConfirmDeletePopup', false)
        return true
      }

      this.deleteFunc(text, confirm)
    },

    // removeSample(index) {
    //   this.MP.removeDigitalSample(index)
    // },

    removeFile(fileModel, index, id) {
      this.$store.dispatch('removeUserDocument', id).then((response) => {
        switch (this.getResponseStatus(response)) {
            /**
             * Success
             */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

            fileModel.data.downloadFiles.splice(index, 1)

            this.openNotify('success', 'common_notificationRecordDeleted')
            break
          }
            /**
             * Validation Error
             */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
            /**
             * Undefined Error
             */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }
      })
    },
  }

}
</script>

<style scoped>

.site-document__img {
  max-width: 65px;
}

.policy >>> a {
  text-decoration: underline;
  color: #cd9e64;
}

@media (max-width: 1100px) {
  .digital-product >>> .order-create__product-delete {
    left: -5px;
  }
}

</style>