import {checkValid} from "@/components/globalModels/functions/ModelsValidate";
import {Files} from "@/components/globalModels/Files";


export function DigitalSampleProduct() {

  this.checkValid = checkValid

  this.data = {
    fileModelId: null,
    name: '',
    isFile: true,
    File: new Files({
      maxCountFiles: 1,
      maxSizeFiles: 20
    }),
    fileUrl: '',
    sortOrder: '',
  }


  this.validation = {
    name: false,
    fileUrl: false,
    sortOrder: false,
  }

  this.validationTranslate = {
    name: '',
    fileUrl: '',
    sortOrder: '',
  }

  this.validationTxt = {
    name: false,
    fileUrl: false,
    sortOrder: false,
  }

  /**
   * Getters
   */

  this.getFileModelId = () => {
    return this.data.fileModelId
  }
  this.getName = () => {
    return this.data.name
  }
  this.getIsFile = () => {
    return this.data.isFile
  }
  this.getFileUrl = () => {
    return this.data.fileUrl
  }
  this.getSortOrder = () => {
    return this.data.sortOrder
  }

  /**
   * Setters
   */

  this.setFileModelId = (val) => {
    return this.data.fileModelId = val
  }
  this.setName = (val) => {
    return this.data.name = val
  }
  this.setIsFile = (val) => {
    return this.data.isFile = val
  }
  this.setFileUrl = (val) => {
    return this.data.fileUrl = val
  }
  this.setSortOrder = (val) => {
    return this.data.sortOrder = val
  }


  /**
   * Global Setters
   */

  DigitalSampleProduct.prototype.setItem = function (item) {
    this.setName(item?.name ? item.name : '')
    this.setIsFile(item?.isFile ? item.isFile : true)
    this.setFileUrl(item?.fileUrl ? item.fileUrl : '')
    this.setSortOrder(item?.sortOrder ? item.sortOrder : '')
  }

  /**
   * Validations
   */

  DigitalSampleProduct.prototype.firstValidationAdmin = function () {

    let validationItems = {
      name: this.getName(),
      sortOrder: this.getSortOrder(),
    }

    let validationOptions = {
      name: {type: ['empty']},
      sortOrder: {type: ['empty']},
    }

    let valid = true
    if(this.getIsFile() && !this.data.File.firstValidation()) {
      valid = false
    }

    return this.checkValid(validationItems, validationOptions) && valid
  }

  /**
   * Prepare Data
   */

  DigitalSampleProduct.prototype.prepareSave = function () {

    return  {

    }

  }

}