<template>
  <div class="frontend">
    <MarketplaceProductsCreationUser
        :MP="MP"
        :categories="categories"
        @save="saveMarketplaceProduct"
    />
  </div>
</template>

<script>
  import MarketplaceProductsCreationUser from "./MarketplaceProductsCreationUser/MarketplaceProductsCreationUser.vue";
  import {MarketplaceProducts} from "@/components/modules/MarketplaceProductsModule/models/MarketplaceProducts";
  import {marketplaceProductsMixin} from "@/mixins/marketplaceProductsMixins/marketplaceProductsMixin";

  export default {
    name: "MarketplaceProductsCreation",

    components:{
      MarketplaceProductsCreationUser,
    },

    mixins: [marketplaceProductsMixin],

    data() {
      return {
        MP: new MarketplaceProducts(),
        categories: [],
      }
    },

    created() {
    },

    mounted() {
      this.$store.dispatch('getMarketplaceProductCategories').then(response => {
        this.categories = response.data
      })

      // filter[shopStatus]=valid&
      this.$store.dispatch('fetchShops', '?filter[type]=12').then(response => {
        this.MP.setShopId(this._.first(this.getRespPaginateData(response))?.id)
      })
    },

    methods: {




    },

  }
</script>

<style scoped>

</style>
