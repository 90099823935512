<template>
  <div class="fragment">

    <div class="content-top-line content-top-line--with-bottom-line "
         v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
          'etsyListing_active',
          'etsyListing_inactive',
          'etsyListing_draft',
        ])"></div>


      <div class="content-tabs content-tabs--separator pt-0">
        <template v-for="(item, index) in navTabs">
          <div class="content-tabs__item"
               :key="index"
               v-bind:class="{active: item.active}"
               @click="$emit('changeTab', item.name)"
          >
            <div class="content-tabs__ico">
              <!--              {{item.id}}  \\-->
              {{item.label}}
            </div>
          </div>
        </template>
      </div>

<!--      <div class="content-top-line-wrap">-->
<!--        <ToggleFilterButton :count="countFilterParams" @toggleFilter="$emit('toggleFilter')" :showFilter="showFilter" />-->
<!--      </div>-->
    </div>

    <div class="light-bg-block">
<!--      <div class="btn-left-block ">-->
<!--        <div class="btn-left-block-i">-->
<!--          <DefaultCheckbox-->
<!--              :label="'Select all'"-->
<!--          />-->
<!--        </div>-->
<!--        <div class="btn-left-block-i">-->

<!--          <div class="btn-left-block__list">-->
<!--            <div class="btn-left-block__list__i">-->
<!--              <TableLeftBlockBtn-->
<!--                  class="btn-left-block-i&#45;&#45;link"-->
<!--                  :value="'Delete'"-->
<!--                  :type="'delete'"-->
<!--              />-->
<!--            </div>-->
<!--          </div>-->

<!--        </div>-->
<!--      </div>-->

      <div class="btn-right-block btn-right-block--mobile-wrap"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['etsyListing_newListing'])"></div>
        <MainButton class="btn-fit-content ml-2"
                    :value="$t('etsyTransfer_DownloadFfromEtsy.localization_value.value')"
                    :ico="true"
                    @click.native="isTransferPopup = true"
        >
          <template slot="ico">
            <TransferIconSVG class="btn-icon-plus" />
          </template>
        </MainButton>
        <router-link :to="$store.getters.GET_PATHS.marketplaceProducts + '/create'">
          <MainButton class="btn-fit-content ml-2"
                      :value="$t('etsyListing_newListing.localization_value.value')"
          >
            <template slot="ico">
              <PlusIconSVG class="btn-icon-plus" />
            </template>
          </MainButton>
        </router-link>
      </div>
    </div>

    <TransferToMarketplacePopup
        v-if="isTransferPopup"
        @reload="$emit('reload')"
        @close="isTransferPopup = false"
    />
  </div>
</template>

<script>
  import MainButton from "../../../../../../../UI/buttons/MainButton/MainButton";
  // import ToggleFilterButton from "../../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";
  // import DefaultCheckbox from "../../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import PlusIconSVG from '../../../../../../../../../public/img/UI-group/plus-icon.svg?inline'
  // import TableLeftBlockBtn from "../../../../../../../coreComponents/TableComponents/TableLeftBlockBtn/TableLeftBlockBtn";
  import TransferIconSVG from '../../../../../../../../../public/img/UI-group/transfer-ico-btn.svg?inline'
  import TransferToMarketplacePopup
    from "@/components/modules/MarketplaceProductsModule/popups/TransferToMarketplacePopup/TransferToMarketplacePopup";


  export default {
    name: "MarketplaceProductsHead",
    components: {
      TransferToMarketplacePopup,
      MainButton,
      // ToggleFilterButton,
      // DefaultCheckbox,
      PlusIconSVG,
      TransferIconSVG,
      // TableLeftBlockBtn,
    },

    props: [
      'showFilter',
      'adminTabs',
      'countFilterParams',
      'activeTab',
      'navTabs'
    ],

    watch: {
      activeTab(newVal) {
        this.tab = newVal
      }
    },

    created() {

    },

    data() {
      return {
        isTransferPopup: false,
        isShowSearchEngineBlock: false,
        tab: this.activeTab,
      }
    },

    methods: {

      changeTab(val) {
        this.$emit('changeTab', val)
      },

      // selectAll(val) {
      //   this.$emit('selectAll', val)
      // },
    }
  }
</script>

<style scoped>
  @media (min-width: 680px) {
    .btn-left-block{
      flex-wrap: wrap;
      max-width: 50%;
    }

    .btn-left-block-i{
      margin-left: 0!important;
      margin-right: 15px;
    }
  }

  @media (max-width: 680px){
    .btn-right-block{
      display: flex;
      flex-direction: column-reverse;
      align-items: flex-end;
    }

    .btn-right-block .btn-right-block-i{
      /*margin-top: 15px;*/
      margin-right: 0!important;
    }

    .btn-right-block .btn-right-block-i:last-child{
      margin-top: 0;
    }

    .btn-left-block{
      display: flex;
      flex-direction: column;
      max-width: 50%
    }

    .btn-left-block-i{
      margin-left: 10px;
      margin-bottom: 10px;
    }

    .btn-left-block-i:last-child{
      margin-top: 0;
      margin-bottom: 0;
    }

    .btn-left-block-i--link{
      font-size: 12px;
    }

    .light-bg-block{
      align-items: flex-end;
      width: calc(100% + 30px);
      margin: 0 -15px;
    }
  }
</style>

