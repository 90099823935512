<template>
  <div>
    <MarketplaceProductsHead
        :countFilterParams="countFilterParams"
        :navTabs="navTabs"
        @reload="$emit('reload')"
        @changeTab="(data) => $emit('changeTab', data)"
    />

    <div class="table-filter-wrap">

<!--      <MarketplaceProductsShopFilter-->
<!--          :showFilter="showFilter"-->
<!--          @close="closeFilter"-->
<!--          @changeFilter="changeFilter"-->
<!--          @resetFilter="$emit('resetFilter')"-->
<!--      />-->

      <MarketplaceProductsTable
          :navTabs="navTabs"
          :shopId="shopId"
          @showMore="$emit('showMore')"
          @resetFilter="$emit('resetFilter')"
          @reload="$emit('reload')"
          @paginate="(page) => {$emit('paginate', page)}"
      />
<!--          :etsyListingsObj="etsyListingsObj"-->
    </div>
  </div>
</template>

<script>


  import MarketplaceProductsHead from "./MarketplaceProductsHead/MarketplaceProductsHead";
  import MarketplaceProductsTable from "./MarketplaceProductsTable/MarketplaceProductsTable";

  export default {
    name: "MarketplaceProductsTableSection",

    components: {
      MarketplaceProductsHead,
      MarketplaceProductsTable,
    },

    props: {
      countFilterParams: Number,
      filterGetParams: Object,
      shopId: [Number, String],
      navTabs: Object,
    },

    data(){
      return{
        etsyListingsObj:{},
      }
    },

    watch: {
      filterGetParams(newVal) {
        this.filterGetParams = newVal
      },
      countFilterParams(newVal) {
        this.countFilterParams = newVal
      },
    },

    mounted() {

    },

    methods: {
      // closeFilter() {
      //   this.showFilter = false
      // },

      // changeFilter(data) {
      //   this.$emit('changeFilter', data)
      // },

    }
  }
</script>

<style scoped>

</style>


