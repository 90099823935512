<template>
  <modal
      @close="$emit('close')"
      class="custom-popup"
  >
    <template slot="header">
      <div
          v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['etsyTransfer_UploadListingsTo'])"></div>
        {{ $t('etsyTransfer_UploadListingsTo.localization_value.value') }}
      </div>
    </template>
    <template slot="body">
      <div class="custom-popup__content mb-4">
        <div class="custom-row">
        </div>
        <div class="custom-row">
          <div class="custom-col custom-col--83 custom-col--xs-100">
            <div class="m-0"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['etsyTransfer_PleaseSelectTheShop'])"></div>
              {{ $t('etsyTransfer_PleaseSelectTheShop.localization_value.value') }}
            </div>
            <!--<p class="m-0" v-else>Here is the progress of uploading the necessary listings</p>-->
          </div>
          <div class="custom-col custom-col--75 custom-col--xs-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['etsyTransfer_UploadTo'])"></div>
            <DefaultSelect
                :options="shopsFrom"
                :optionsLabel="'shop_name'"
                :label="$t('etsyTransfer_UploadFrom.localization_value.value')"
                :selected="activeShopFrom"
                @change="changeShopFrom"
            />
          </div>
          <div class="custom-col custom-col--75 custom-col--xs-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['etsyTransfer_UploadTo'])"></div>
            <DefaultSelect
                :options="shopsTo"
                :optionsLabel="'shop_name'"
                :label="$t('etsyTransfer_UploadTo.localization_value.value')"
                :selected="activeShopTo"
                @change="changeShopTo"
            />
          </div>
<!--          <div class="custom-col" v-else>-->
<!--            <div class="transfer-etsy">-->
<!--              <div class="transfer-etsy__items">-->
<!--                <div class="transfer-etsy__item"-->
<!--                     v-for="(item, index) in items"-->
<!--                     :key="index"-->
<!--                >-->
<!--                  <div class="transfer-etsy__remove"></div>-->
<!--                  <div class="transfer-etsy__right">-->
<!--                    <div class="transfer-etsy__name">-->
<!--                      {{ item.payload.title }}-->
<!--                    </div>-->
<!--                    <div class="transfer-etsy__success"-->
<!--                         v-if="_.indexOf(checkedIDs, item.id) === -1"-->
<!--                    ></div>-->
<!--                    <div class="transfer-etsy__error"-->
<!--                         v-if="_.indexOf(checkedIDsError, item.id) !== -1"-->
<!--                    ></div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <DotsShadowPreloader-->
<!--                    class="mt-3"-->
<!--                    v-if="checkedIDs.length - checkedIDsError.length !== 0"-->
<!--                />-->
<!--              </div>-->

<!--            </div>-->
<!--          </div>-->

<!--          {{transferredArray}}-->

        </div>
      </div>
    </template>
    <template slot="footer">
      <div class="buy-label__btn d-flex align-items-center"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'common_cancel',
          'common_upload',
        ])"></div>
        <span
            class="site-link site-link--alt buy-label__btn-i mr-3"
            @click="$emit('close')"
            v-if="checkedIDs.length - checkedIDsError.length === 0"
        >
          {{ $t('common_cancel.localization_value.value') }}
        </span>

        <MainButton
            :value="$t('common_upload.localization_value.value')"
            class="buy-label__btn-i"
            @click.native="upload"
            v-bind:class="{'disabled-btn' : isUpload}"
        />
      </div>
    </template>
  </modal>

</template>

<script>
import Modal from '../../../../commonModals/Modal.vue'
import MainButton from '../../../../UI/buttons/MainButton/MainButton.vue'
import DefaultSelect from "../../../../UI/selectiones/DefaultSelect/DefaultSelect";
import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";
import {SHOP_TYPES} from "../../../../../staticData/staticVariables";
import {etsyTransferSocketMixin} from "../../../../../mixins/socketMixins/socketMixin";
// import DotsShadowPreloader from "../../../../coreComponents/preloaders/DotsShadowPreloader/DotsShadowPreloader";

export default {
  name: "TransferToMarketplacePopup",
  components: {
    // DotsShadowPreloader,
    Modal,
    MainButton,
    DefaultSelect,
  },

  mixins: [routeFilter, etsyTransferSocketMixin],

  props: {
    shopId: [String, Number],
  },

  data() {
    return {
      search: '',
      shopsFrom: [],
      shopsTo: [],

      activeShopTo: {},
      activeShopFrom: {},
      checkedIDs: [],
      checkedIDsError: [],
      items: [],
      transferredArray: [],

      isUpload: false,
    }
  },

  mounted() {
    this.$store.dispatch(
      'fetchShops',
      `?filter[type]=${SHOP_TYPES.ukrainiantreasures.id}&filter[shopStatus]=valid`
    ).then(response => {
      this.shopsTo = this.getRespPaginateData(response)
      if(this.shopsTo.length > 0 && !this.isAdmin) {
        this.activeShopTo = this._.first(this.shopsTo)
      }
    })

    this.$store.dispatch(
      'fetchShops',
      `?filter[type]=${SHOP_TYPES.etsy.id}&filter[shopStatus]=valid`
    ).then(response => {
      this.shopsFrom = this.getRespPaginateData(response)
    })
  },

  beforeDestroy() {
    if(this.$store.getters.getEtsyTransferSocket){
      this.destroySocketChannel('EtsyTransfer', 'setEtsyTransferSocket')
    }
  },

  methods: {

    async getListingForDownload(page = 1) {
      this.isUpload = true

      let data = {
        'shop_id': this.activeShopFrom.id,
        'download': true,
        'page': page,
      }

      let response = await this.getListingForDownloadProcess(data)

      if(!response.status) {
        return false
      }

      if(response.status && !response.lastPage) {
        let nextResponse = await this.getListingForDownload(Number(data.page) + 1)
        return nextResponse
      }

      if(response.status && response.lastPage) {
        return true
      }

    },

    async getListingForDownloadProcess(data) {
      return this.$store.dispatch('getListingsForDownload', data).then((response) => {
        switch (this.getResponseStatus(response)) {
            /**
             * Success
             */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

            return {status: true, lastPage: this.getRespData(response)?.is_last_page}
          }
            /**
             * Validation Error
             */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            this.isUpload = false
            return {status: false}
          }
            /**
             * Undefined Error
             */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
            this.isUpload = false
            return {status: false}
          }
        }

        // if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
        //   // this.openNotify('success', 'common_notificationRecordChanged')
        //   return true
        // } else {
        //   setTimeout(() => {
        //     this.openNotify('error', 'common_notificationUndefinedError')
        //   }, 200)
        //   this.isUpload = !this.isUpload
        //   return false
        // }
      })
    },


    async upload() {
      // let checkboxIds = this.getCheckedRows('row-name')
      //
      // if (checkboxIds.length === 0) {
      //   this.openNotify('error', 'common_notificationPleaseChooseItem')
      //   return
      // }
      // this.checkedIDs = Array.from(checkboxIds, el => parseInt(el))

      // this.items = []
      // checkboxIds.map((id) => {
      //   let item = this._.find(this.$store.getters.getEtsyTransfer, {id: parseInt(id)})
      //   this.items.push(item)
      // })

      let success = null
      await this.getListingForDownload().then(response => { success = response })

      console.log(success);
      console.log(321321);

      if(!success) return

      let data = {
        'from_shop_id': this.activeShopFrom.id,
        'to_shop_id': this.activeShopTo.id,
        // 'transfer_listing_ids': checkboxIds
      }

      /**
       * Create Socket Channel
       * @type {*|{channel, active, event, user}|{active}}
       */
      let prepareSocket = this.prepareCreatePrivateSocketChannel('EtsyTransfer')
      if (prepareSocket.active) {
        this.createEtsyTransferSocket(
            prepareSocket,
            (response) => {

              console.log(response);

              // this.transferredArray.push(response.data)

              if(response.data.last_item) {
                this.openNotify('success', 'common_notificationRecordChanged')
                this.$emit('reload')
                return this.$emit('close')
              }

              if (response.data.status === true) {
                this.checkedIDs.splice(this._.indexOf(this.checkedIDs, response.data.id), 1)
              }

              if (response.data.status === false) {
                this.checkedIDsError.push(response.data.id)
              }
            }
        )
      }

      this.$store.dispatch('listingsTransferToShop', data).then(response => {

        switch (this.getResponseStatus(response)) {
            /**
             * Success
             */

          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
            // this.openNotify('success', 'common_notificationRecordChanged')
            // this.$emit('close')
            // if(index === checkboxIds.length - 1){
            //
            // }
            break
          }
            /**
             * Validation Error
             */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)

            if(response.response.data['response-type'] === 'info') {
              this.$emit('close')
            }
            break
          }
            /**
             * Undefined Error
             */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }

      })

      // ids.push(parseInt(id))


    },

    changeShopTo(val) {
      this.activeShopTo = val
    },

    changeShopFrom(val) {
      this.activeShopFrom = val
    }
  }

}

</script>

<style scoped lang="scss">
@import '../../../../../scss/colors';
@import '../../../../../scss/mixins/mixins';

.transfer-etsy {
  width: 100%;

  &__items {

  }

  &__item {
    display: flex;
    align-items: flex-end;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__remove {
    width: 35px;
    min-width: 35px;
    display: flex;
    height: 35px;
    background-image: url("../../../../../assets/img/UI-group/delete-icon-transfer.svg");
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    margin-left: -20px;

    @include for-550 {
      margin-left: -15px;
    }
  }

  &__right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid #F8F4EE;
    padding-bottom: 5px;

    @include for-550 {
      max-width: calc(100% - 15px);
    }
  }

  &__name {
    width: 100%;
    max-width: 325px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 500;
    font-size: 14px;

    @include for-550 {
      max-width: calc(100% - 40px);
    }
  }

  &__success {
    width: 18px;
    height: 18px;
    background: url("../../../../../assets/img/common/success-mark.svg") center/contain no-repeat;

    @include for-550 {
      margin-left: 10px;
    }
  }

  &__error {
    width: 18px;
    height: 18px;
    background: url("../../../../../assets/img/common/error-mark.svg") center/contain no-repeat;

    @include for-550 {
      margin-left: 10px;
    }
  }

}


</style>
