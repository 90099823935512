<template>
  <div class="table-card__item-content"
       :class="{show: show}"
  >
    <div class="table-card__item-head">
      <div class="table-card__item-head-row">
        <div class="table-card__item-number table-card__item-number--small">
          <div class="table-row">
            <LabelCircle :color="'green'" :value="'A'" class="mr-1"
                         v-if="item['state'] === 'active'"
            />
            <StatusIcoBtn :type="'cancel'" class="mr-1 pb-1"
                          v-else
            />

            <ValueHelper
                :value="'#' + item['id']"
            />
          </div>
        </div>
      </div>
    </div>
    <transition name="slide">
      <div class="table-card__item-body"
           v-if="show"
      >
        <div class="table-card__item-row custom-row">
          <div class="table-card__item-col custom-col custom-col">
            <div class="table-card__item-label">
              {{$t('etsyListing_title.localization_value.value')}}
            </div>
            <div class="table-card__item-info flex-wrap" style="gap: 6px; display: block;">
              <TooltipTextHelper
                  class="etsy-listings-title"
                  v-if="item['title']"
                  :text="item['title']"
                  :paragraph="item['title']"
                  :styleClass="'white-space-normal'"
              />
              <ValueHelper
                  v-else
              />
              <div v-if="item.is_private">
                <a class="site-link" :href="item.url_key" target="_blank">ukrainiantreasures.com</a>
              </div>
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--33">
            <div class="table-card__item-label">
              {{$t('etsyListing_price.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  :value="item['price']"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--33">
            <div class="table-card__item-label">
              {{$t('etsyListing_qTy.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  :value="item['quantity']"
              />
            </div>
          </div>
        </div>

        <div class="table-card__item-btn">
          <div class="table-card__item-btn-i">
            <LinkButton
                :value="$t('common_edit.localization_value.value')"
                :type="'edit'" :link="$store.getters.GET_PATHS.marketplaceProducts + `/${shopId}` + `/${item['id']}`"
                v-bind:class="{'disabled-btn' : $store.getters.getMarketplaceProductsBtn}"
            />
          </div>
          <div class="table-card__item-btn-i">
            <LinkButton
                :value="$t('common_delete.localization_value.value')"
                :type="'delete'"
                @click.native="$emit('removeItem')"
                v-bind:class="{'disabled-btn' : $store.getters.getMarketplaceProductsBtn}"
            />
          </div>
<!--          <div class="table-card__item-btn-i">-->
<!--            <LinkButton-->
<!--                :value="$t('common_copy.localization_value.value')"-->
<!--                :type="'copy'"-->
<!--                @click.native="$emit('cloneItem')"-->
<!--                v-bind:class="{'disabled-btn' : $store.getters.getMarketplaceProductsBtn}"-->
<!--            />-->
<!--          </div>-->
        </div>
      </div>
    </transition>
    <div class="table-card__item-footer">
      <div class="table-card__item-show"
           @click="show = !show"
      >
        {{$t('common_showMore.localization_value.value')}}
      </div>
    </div>
  </div>
</template>

<script>

import ValueHelper from "../../../../../../../../coreComponents/ValueHelper/ValueHelper";
import LinkButton from "../../../../../../../../UI/buttons/LinkButton/LinkButton";
// import DefaultCheckbox from "../../../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
import LabelCircle from "../../../../../../../../coreComponents/LabelCircle/LabelCircle";
import StatusIcoBtn from "@/components/UI/status/StatusIcoBtn/StatusIcoBtn";
import TooltipTextHelper from "@/components/coreComponents/TooltipTextHelper/TooltipTextHelper";

export default {
  name: "MarketplaceProductsTableMobile",

  components: {
    TooltipTextHelper,
    StatusIcoBtn,
    ValueHelper,
    LinkButton,
    // DefaultCheckbox,
    LabelCircle,
  },

  props: {
    item: Object,
    shopId: [Number, String],
  },

  data() {
    return {
      show: false,
    }
  }

}
</script>

<style scoped>

</style>
